import {
  PropsWithChildren,
  createContext,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react';

// ==========================================================================
// useMultiStep

export type MultiStepReturnType = {
  steps: string[];
  visitedSteps: string[];
  remainingSteps: string[];
  pastSteps: string[];
  currentStep: string;
  currentStepIndex: number;
  isFirstStep: boolean;
  isLastStep: boolean;
  percentProgress: number;
  stepsToSkip: string[];
  isRemainingStep: (step: string) => boolean;
  isPastStep: (step: string) => boolean;
  isCurrentStep: (step: string) => boolean;
  isVisitedStep: (step: string) => boolean;
  unregisterStep: (stepToRemove: string) => void;
  registerStep: (step: string) => void;
  getNextStep: (step: string) => string | undefined;
  getPrevStep: (step: string) => string | undefined;
  goNext: () => void;
  goBack: () => void;
  goToStep: (step: string) => void;
};

export type UseMultiStepOptions = {
  steps?: string[];
  initialStep?: string;
  skip?: string[];
  onStepChange?: (prevStep: string, nextStep: string) => void;
};

export const useMultiStep = (
  options: UseMultiStepOptions = {}
): MultiStepReturnType => {
  const [steps, setSteps] = useState<string[]>(options?.steps || []);
  const [currentStep, setCurrentStep] = useState('');
  const optionsRef = useRef(options);

  const currentStepIndex = steps.indexOf(currentStep);
  const isFirstStep = currentStepIndex === 0;
  const isLastStep = currentStepIndex === steps.length - 1;
  const visitedSteps = steps.filter((_, i) => i <= currentStepIndex);
  const remainingSteps = steps.filter((step) => !visitedSteps.includes(step));
  const pastSteps = visitedSteps.filter((vs) => vs !== currentStep);
  const percentProgress = ((currentStepIndex + 1) / steps.length) * 100;
  const stepsToSkip = options?.skip || [];

  const isRemainingStep = (step: string) => remainingSteps.includes(step);
  const isPastStep = (step: string) => pastSteps.includes(step);
  const isCurrentStep = (step: string) => step === currentStep;
  const isVisitedStep = (step: string) => visitedSteps.includes(step);

  const registerStep = (newStep: string) => {
    setSteps((prev) => Array.from(new Set([...prev, newStep])));
  };

  const unregisterStep = (stepToRemove: string) => {
    setSteps((prev) => {
      const oldSet = new Set([...prev]);
      oldSet.delete(stepToRemove);

      return Array.from(oldSet);
    });
  };

  const getNextStep = (step: string) => {
    const stepIndex = steps.indexOf(step);
    const nextStep = steps[stepIndex + 1];

    if (optionsRef?.current?.skip?.includes(nextStep)) {
      return steps[stepIndex + 2];
    }
    return nextStep;
  };

  const getPrevStep = (step: string) => {
    const stepIndex = steps.indexOf(step);
    const prevStep = steps[stepIndex - 1];

    if (!prevStep) return undefined;
    return prevStep;
  };

  const recursiveFindNextStep = (currStep: string) => {
    const nextStep = getNextStep(currStep);

    if (!nextStep) {
      return currStep;
    }

    if (optionsRef.current?.skip?.includes(nextStep)) {
      return recursiveFindNextStep(nextStep);
    }
    return nextStep;
  };

  const recursiveFindPrevStep = (currStep: string) => {
    const prevStep = getPrevStep(currStep);

    if (!prevStep) {
      return currStep;
    }

    if (optionsRef.current?.skip?.includes(prevStep)) {
      return recursiveFindPrevStep(prevStep);
    }
    return prevStep;
  };

  const goNext = () => {
    setCurrentStep((prev) => {
      const stepIndex = steps.indexOf(prev);
      const currStep = steps[stepIndex];
      const nextStep = recursiveFindNextStep(currStep);

      if (options?.onStepChange) {
        options.onStepChange(prev, nextStep);
      }

      return nextStep;
    });
  };

  const goBack = () => {
    setCurrentStep((prev) => {
      const stepIndex = steps.indexOf(prev);
      const currStep = steps[stepIndex];
      const prevStep = recursiveFindPrevStep(currStep);

      if (options?.onStepChange) {
        options.onStepChange(prev, prevStep);
      }
      return prevStep;
    });
  };

  const goToStep = (step?: string) => {
    setCurrentStep(step || steps[0]);
  };

  useEffect(() => {
    if (steps.length > 0 && !currentStep) {
      setCurrentStep(steps[0]);
    }
  }, [steps, currentStep]);

  useEffect(() => {
    if (options?.initialStep) {
      setCurrentStep(options.initialStep);
    }
  }, [options?.initialStep]);

  useEffect(() => {
    optionsRef.current = options;
  }, [options]);

  return {
    steps,
    visitedSteps,
    remainingSteps,
    pastSteps,
    currentStep,
    currentStepIndex,
    isFirstStep,
    isLastStep,
    percentProgress,
    stepsToSkip,
    isRemainingStep,
    isPastStep,
    isCurrentStep,
    isVisitedStep,
    unregisterStep,
    registerStep,
    getNextStep,
    getPrevStep,
    goNext,
    goBack,
    goToStep,
  };
};

// ==========================================================================
// MultiStep Context

const MultiStepContext = createContext<MultiStepReturnType | undefined>(
  undefined
);

export const useMultiStepContext = () => {
  const ctx = useContext(MultiStepContext);
  if (!ctx) throw new Error('Missing MultiStep');
  return ctx;
};

const Root = (props: PropsWithChildren<MultiStepReturnType>) => (
  <MultiStepContext.Provider value={props}>
    {props.children}
  </MultiStepContext.Provider>
);

// ==========================================================================
// Step

type StepProps = PropsWithChildren<{
  name: string;
}>;

const Step = ({ children, name }: StepProps) => {
  const { registerStep, isCurrentStep, unregisterStep } = useMultiStepContext();

  useEffect(() => {
    registerStep(name);
    return () => unregisterStep(name);
  }, []);

  if (isCurrentStep(name)) return <>{children}</>;
  return <></>;
};

export const MultiStep = {
  Root,
  Step,
};
