import { colors as particleTokensColors } from '@homelight/particle-tokens';

const { coolGray7 } = particleTokensColors;

const breakpointsValue = {
  xs: 0,
  sm: 600,
  md: 960,
  lg: 1280,
  xl: 1920,
};

// It must be the first object to be exported
export const colors = {
  // Primary Colors
  darkBlue: '#273653',
  electricBlue: '#46B6FF',
  aaBlue: '#1192E5',

  // UI
  // TODO: Replace these with the tokens from @homelight/particle-tokens
  blue100: '#F2F9FE',
  blue300: '#D0ECFF',
  blue400: '#8BD1FF',
  blue500: '#46B6FF',
  blue700: '#0C6EBA',
  blue800: '#47517A',
  coolGray1: '#55585E',
  coolGray2: '#8E929C',
  coolGray3: '#C5C8CD',
  coolGray4: '#DBDFE6',
  coolGray5: '#EEF0F6',
  coolGray6: '#F5F6F9',
  coolGray7,
  frostGray: '#BBD3EA',
  slateGray: '#9BB0C9',
  gray50: '#F8F8FB',
  gray500: '#8E929C',
  gray600: '#72757D',
  gray700: '#55585E',
  gray800: '#3A3C40',
  green100: '#EFFAF6',
  green300: '#CEF0E4',
  green400: '#87D8BB',
  green500: '#38BF8F',
  green600: '#3B855E',
  darkGray: '#222222',
  black: '#000000',
  white: '#FFFFFF',
  cadmiumRed: '#F7382D',
  red2: '#FEEBEA',
  red100: '#FFF4F4',
  red200: '#FFF4F4',
  red700: '#CA0D02',
  red800: '#8E000E',
  dandelionYellow: '#FFDA6B',
  yellow300: '#FFECB5',
  yellow600: '#F1B707',
  yellow700: '#BB8E00',
  yellow800: '#705300',
  electricBlueHover: '#42ADF2',
  whiteHover: '#F2F2F2',
  transparent: 'transparent',
  confirm: '#38BF8F',
  warningLight: '#FFF6EB',
  warningDark: '#D64F00',
  red300: '#FFD4D2',
  bbysBlue: '#47517A',
  blueGray: '#55677B',
  orange500: '#FF9000',
  orange600: '#F06C00',
  orange800: '#A83500',

  // Illustration
  mediumBlue: '#0C6EBA',
  cobaltBlue: '#008CE7',
  glacierBlue: '#B6E2FF',
  stoneGray: '#E8EEF9',
  mistBlue: '#F2F8FE',
  lightBlue: '#E9F6FF',

  // Elite Agent
  silver: '#cccccc',
  stratos: '#00032D',
} as const;

export type Colors = keyof typeof colors;
export type ColorValues = typeof colors[Colors];

export const avatar = {
  size: {
    small: { fontSize: 12, size: 30 },
    medium: { fontSize: 14, size: 40 },
    big: { fontSize: 16, size: 50 },
    extraBig: { fontSize: 16, size: 60 },
    xxl: { fontSize: 16, size: 64 },
    xxxl: { fontSize: 24, size: 88 },
  },
  surface: {
    dark: {
      color: colors.white,
      backgroundColor: colors.darkBlue,
    },
    light: {
      color: colors.coolGray2,
      backgroundColor: colors.coolGray5,
    },
    blue: {
      color: colors.white,
      backgroundColor: colors.electricBlue,
    },
    alert: {
      color: colors.white,
      backgroundColor: colors.cadmiumRed,
    },
    lightYellow: {
      color: 'inherit',
      backgroundColor: '#FFECB5',
    },
    none: {
      color: 'unset',
      backgroundColor: 'unset',
    },
  },
};

export const buttons = {
  primary: {
    color: colors.white,
    backgroundColor: colors.electricBlue,
    borderSize: '0px',
    border: '',
    borderColor: '',
    hover: {
      color: colors.white,
      backgroundColor: colors.electricBlueHover,
      boxShadow: 'none',
    },
    focus: {
      color: colors.white,
      backgroundColor: colors.electricBlueHover,
      boxShadow: 'none',
    },
    disabled: {
      backgroundColor: colors.coolGray4,
      color: colors.coolGray2,
    },
  },
  secondary: {
    color: colors.electricBlue,
    backgroundColor: colors.white,
    borderSize: '1px',
    border: `1px solid ${colors.electricBlue}`,
    borderColor: colors.electricBlue,
    hover: {
      backgroundColor: colors.lightBlue,
      boxShadow: 'none',
    },
    focus: {
      backgroundColor: colors.whiteHover,
      boxShadow: 'none',
    },
    disabled: {
      backgroundColor: colors.coolGray4,
      color: colors.coolGray2,
    },
  },
  'secondary-transparent': {
    color: colors.electricBlue,
    backgroundColor: colors.transparent,
    borderSize: '1px',
    border: `1px solid ${colors.electricBlue}`,
    borderColor: colors.electricBlue,
    hover: {
      backgroundColor: colors.lightBlue,
      boxShadow: 'none',
    },
    focus: {
      backgroundColor: colors.whiteHover,
      boxShadow: 'none',
    },
    disabled: {
      backgroundColor: colors.coolGray4,
      color: colors.coolGray2,
    },
  },
  'secondary-inverted': {
    color: colors.white,
    backgroundColor: 'transparent',
    borderSize: '1px',
    border: `1px solid ${colors.white}`,
    borderColor: colors.white,
    transition: 'all 100ms ease-in-out',
    hover: {
      backgroundColor: 'rgba(255, 255, 255, .1)',
      boxShadow: 'none',
    },
    focus: {
      backgroundColor: colors.whiteHover,
      boxShadow: 'none',
    },
    disabled: {
      backgroundColor: colors.coolGray4,
      color: colors.coolGray2,
    },
  },
  tertiary: {
    color: colors.darkBlue,
    backgroundColor: colors.white,
    borderSize: '0px',
    border: ``,
    borderColor: '#DBDFE6',
    hover: {
      color: colors.darkBlue,
      backgroundColor: colors.whiteHover,
      boxShadow: 'none',
    },
    focus: {
      color: colors.darkBlue,
      backgroundColor: colors.white,
      boxShadow: 'none',
    },
    disabled: {
      backgroundColor: colors.coolGray4,
      color: colors.coolGray2,
    },
  },
  'primary-ghost': {
    color: colors.electricBlue,
    backgroundColor: 'transparent',
    borderSize: '0px',
    border: ``,
    borderColor: '',
    hover: {
      color: colors.electricBlue,
      backgroundColor: 'transparent',
      boxShadow: 'none',
    },
    focus: {
      color: colors.electricBlue,
      backgroundColor: 'transparent',
      boxShadow: 'none',
    },
    disabled: {
      backgroundColor: 'transparent',
      color: colors.coolGray2,
    },
  },
  'tertiary-transparent': {
    color: colors.darkBlue,
    backgroundColor: colors.transparent,
    borderSize: '0px',
    border: ``,
    borderColor: '#DBDFE6',
    hover: {
      color: colors.darkBlue,
      backgroundColor: colors.whiteHover,
      boxShadow: 'none',
    },
    focus: {
      color: colors.darkBlue,
      backgroundColor: colors.white,
      boxShadow: 'none',
    },
    disabled: {
      backgroundColor: colors.coolGray4,
      color: colors.coolGray2,
    },
  },
  urgent: {
    color: colors.white,
    backgroundColor: colors.cadmiumRed,
    borderSize: '0px',
    border: ``,
    borderColor: '',
    hover: {
      color: colors.white,
      backgroundColor: colors.cadmiumRed,
      boxShadow: '0 1px 1px 0 rgba(39,54,83,.2)',
    },
    focus: {
      color: colors.white,
      backgroundColor: colors.cadmiumRed,
      boxShadow: 'inset 0 0 1px 0 rgba(39,54,83,.4)',
    },
    disabled: {
      backgroundColor: colors.coolGray4,
      color: colors.coolGray2,
    },
  },
  custom: {
    color: colors.white,
    backgroundColor: colors.darkBlue,
    borderSize: '0px',
    border: ``,
    borderColor: '',
    hover: {
      color: colors.white,
      backgroundColor: colors.darkBlue,
      boxShadow: 'none',
    },
    focus: {
      color: colors.white,
      backgroundColor: colors.darkBlue,
      boxShadow: 'none',
    },
    disabled: {
      color: colors.white,
      backgroundColor: colors.darkBlue,
      boxShadow: 'none',
    },
  },
  sizes: {
    xsmall: {
      lineHeight: 'calc(1.5rem - var(--border-size) * 2)',
      padding: '0 0.75rem',
      fontSize: '0.75rem',
      borderRadius: '4px',
    },
    small: {
      lineHeight: 'calc(2rem - var(--border-size) * 2)',
      padding: '0 0.75rem',
      fontSize: '0.75rem',
      borderRadius: '4px',
    },
    medium: {
      lineHeight: 'calc(2.5rem - var(--border-size) * 2)',
      padding: '0 1.25rem',
      fontSize: '0.75rem',
      borderRadius: '6px',
    },
    large: {
      lineHeight: 'calc(3rem - var(--border-size) * 2)',
      padding: '0 1.25rem',
      fontSize: '1rem',
      borderRadius: '6px',
    },
  },
};

export type Buttons = Exclude<keyof typeof buttons, 'sizes'>;
export type ButtonSizes = keyof typeof buttons['sizes'];

const leadContext = {
  mobile: {
    backgroundColor: colors.white,
  },
  desktop: {
    backgroundColor: colors.white,
  },
};

const messagesList = {
  boxShadow: `0px 0px 5px ${colors.coolGray1}`,
  borderRadius: '3px',
};

const messageThread = {
  unreadColor: colors.coolGray5,
  readColor: colors.white,
};

const messageBubble = {
  right: {
    backgroundColor: colors.darkBlue,
    color: colors.white,
  },
  left: {
    backgroundColor: colors.coolGray5,
    color: colors.coolGray2,
  },
};

export type Paragraphs =
  | 'heading-xl'
  | 'heading-0'
  | 'heading-1'
  | 'heading-2'
  | 'heading-3.5'
  | 'heading-3'
  | 'heading-4'
  | 'heading-5'
  | 'heading-6'
  | 'paragraph-big'
  | 'text-large-semi-bold'
  | 'text'
  | 'text-bold'
  | 'text-semi-bold'
  | 'text-small'
  | 'text-small-bold'
  | 'text-small-semi-bold'
  | 'text-xsmall'
  | 'text-xsmall-bold'
  | 'text-xsmall-semi-bold'
  | 'text-micro'
  | 'light-title';

export const paragraphs: {
  [key in Paragraphs]: {
    fontSize: string;
    fontWeight?: string | number;
    letterSpacing?: string;
    color?: string;
  };
} = {
  'heading-xl': {
    fontSize: '56px',
    fontWeight: 'bold',
    letterSpacing: '-1px',
  },
  'heading-0': {
    fontSize: '40px',
    fontWeight: 'bold',
    letterSpacing: '-1px',
  },
  'heading-1': {
    fontSize: '36px',
    fontWeight: 'bold',
    letterSpacing: '-1px',
  },
  'heading-2': {
    fontSize: '32px',
    fontWeight: 'bold',
    letterSpacing: '-0.8px',
  },
  'heading-3.5': {
    fontSize: '28px',
    fontWeight: 'bold',
    letterSpacing: '-0.6px',
  },
  'heading-3': {
    fontSize: '24px',
    fontWeight: 'bold',
    letterSpacing: '-0.6px',
  },
  'heading-4': {
    fontSize: '20px',
    fontWeight: 'bold',
    letterSpacing: '-0.5px',
  },
  'heading-5': {
    fontSize: '16px',
    fontWeight: 'bold',
  },
  'heading-6': {
    fontSize: '14px',
    fontWeight: 'bold',
  },
  'paragraph-big': {
    fontSize: '20px',
    letterSpacing: '-0.5px',
  },
  'text-large-semi-bold': {
    fontSize: '20px',
    fontWeight: 600,
  },
  text: {
    fontSize: '16px',
  },
  'text-bold': {
    fontSize: '16px',
    fontWeight: 'bold',
  },
  'text-semi-bold': {
    fontSize: '16px',
    fontWeight: 600,
  },
  'text-small': {
    fontSize: '14px',
  },
  'text-small-bold': {
    fontSize: '14px',
    fontWeight: 'bold',
  },
  'text-small-semi-bold': {
    fontSize: '14px',
    fontWeight: 600,
  },
  'text-xsmall': {
    fontSize: '12px',
  },
  'text-xsmall-bold': {
    fontSize: '12px',
    fontWeight: 'bold',
  },
  'text-xsmall-semi-bold': {
    fontSize: '12px',
    fontWeight: 600,
  },
  'text-micro': {
    fontSize: '10px',
  },

  // Custom (not documented)
  'light-title': {
    fontSize: '18px',
    fontWeight: '400',
  },
};

export const panel = {
  padding: 2,
  paddingBottom: 3,
  border: `1px solid ${colors.coolGray4}`,
  borderRadius: '8px',
  backgroundColor: colors.white,
  contrastBackgroundColor: colors.coolGray6,
  alertColor: colors.cadmiumRed,
};

export const spacing = {
  xxs: 0.4,
  xs: 0.6,
  s: 1,
  m: 2,
  l: 3,
  xl: 4,
  xxl: 5,
  xxxl: 6,
};

export const icons = {
  'angle-left': {
    file: '/icons/angle-left-light.svg',
    alt: 'Left Arrow icon',
    width: 9,
    height: 16,
  },
  'angle-right': {
    file: '/icons/angle-right-light.svg',
    alt: 'Left Arrow icon',
    width: 9,
    height: 16,
  },
  'document-error': {
    file: '/icons/document-error.svg',
    alt: 'Document Error icon',
    width: 51,
    height: 51,
  },
  alert: {
    file: '/icons/alert.svg',
    alt: 'Alert icon',
    width: 51,
    height: 51,
  },
};

export type Icons = keyof typeof icons;

// Alphabetical order
export const theme = {
  avatar,
  breakpointsValue,
  breakpoints: {
    keys: ['xs', 'sm', 'md', 'lg', 'xl'],
    up: (key: string): string =>
      `@media (min-width:${breakpointsValue[key]}px)`,
  },
  buttons,
  colors,
  leadContext,
  messageBubble,
  messagesList,
  messageThread,
  panel,
  paragraphs,
  spacing: 10,
};

export type ThemeTypes = typeof theme;
declare module 'styled-components' {
  // eslint-disable-next-line @typescript-eslint/no-empty-interface
  export interface DefaultTheme extends ThemeTypes {}
}
